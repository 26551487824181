import { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Grid, Hidden, LinearProgress } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { DatePicker } from '../../lib/formik/DatePicker';
import { useDispatch } from 'react-redux';
import { SubHeading } from '../investors/components';
import { addPlan, fundAccess, getPlanDetail, updatePlan } from '../../redux-store/actions/funds';
import {
  accessType,
  addPlanType,
  ClassPlanProps,
  topUpPlanType,
} from '../../redux-store/types/funds';
import MFSelectField from '../../lib/formik/SelectField';
import { Location } from 'history';
import { getFundManagersList, getTrustessList } from '../../redux-store/actions/userManagement';
import { DistributorListType, FundManager, Trustee } from '../../redux-store/types/api-types';
import { addPlanValidation } from '../../utils/schema';
import {
  allowOnlyNumbers,
  currencyConversion,
  isAccreditedInvestorApplicable,
  isFieldApplicable,
  isFieldVariable,
  getArrayLength,
  UpdatedTopUpValues,
} from '../../utils/utilityFunctions';
import {
  CurrencyMaster,
  FEE_TYPE_MASTER,
  SCHEME_CATEGORY,
  YES_NO_MASTER,
  defaultCurrency,
} from '../../utils/constant';
import { styles } from '../../Styles/styles';
import { MultipleSelect } from '../../lib/formik/MultipleSelectField';
import { getDistributorsListForFilters } from '../../redux-store/actions';
import MFCheckbox from '../../lib/formik/Checkbox';
import UseRadioGroup from '../../lib/formik/Radio';

type NewPlanType = addPlanType & {
  isApplicableForDistributor: boolean;
};

export const topUpPlanInitialValues: topUpPlanType = {
  isTopupApplicable: false,
  topUpSetupFeeApplicable: '',
  topUpSetupFeeType: null,
  topUpMaxSetupFee: null,
  topUpMinSetupFee: null,
  topUpSetupFee: null,
  topUpMinCommitmentAmount: null,
  topUpMaxCommitmentAmount: 0,
  topUpMinContributionPercentage: 0,
  topUpAccreditedInvestorApplicable: '',
  topUpAccreditedMinCommitmentAmount: null,
  topUpManagementFeeApplicable: '',
  topUpManagementFeeType: null,
  topUpMinManagementFee: null,
  topUpMaxManagementFee: null,
  defaultTopUpManagementFee: null,
  topUpHurdleRateApplicable: '',
  topUpHurdleRateType: '',
  defaultTopUpHurdleRate: null,
  topUpMinHurdleRate: null,
  topUpMaxHurdleRate: null,
  topUpPerformanceFeeApplicable: '',
  topUpPerformanceFeeType: null,
  defaultTopUpPerformanceFee: null,
  topUpMinPerformanceFee: null,
  topUpMaxPerformanceFee: null,
  topUpExitChargeApplicable: '',
  topUpExitChargeType: null,
  defaultTopUpExitCharge: null,
  topUpMinExitCharge: null,
  topUpMaxExitCharge: null,
  topUpPlacementFeeApplicable: '',
  topUpPlacementFeeType: '',
  topUpMaxPlacementFee: null,
  topUpMinPlacementFee: null,
  defaultTopUpPlacementFee: null,
  topUpOperatingExpenseApplicable: '',
  topUpOperatingExpenseType: '',
  topUpMaxOperatingExpense: null,
  topUpMinOperatingExpense: null,
  defaultTopUpOperatingExpenses: null,
};
const initialValues: NewPlanType = {
  // id: '',
  schemeId: 0,
  planCode: '',
  planDescription: '',
  schemeNature: '',
  registrationNumber: '',
  schemeCategory: '',
  country: '',
  currency: defaultCurrency,
  schemeStartDate: `${new Date()}`,
  lockInPeriod: '',
  lockInExtension: '',
  faceValue: 0,
  fundAddress1: '',
  fundmanagerIds: null,
  fundmanagerDetails: { fundmanagerIds: [], fundmanagers: [] },
  trusteeDetails: { trusteeIds: [], trustees: [] },
  trusteeIds: null,
  requiredTrustees: null,
  requiredFundManagers: null,
  fundAddress2: '',
  fundAddress3: '',
  inActive: true,
  isActive: true,
  custodian: '',
  // setupFee: 0,
  minCommitmentAmount: null,
  maxCommitmentAmount: 0,
  minContributionPercentage: 0,
  defaultManagementFee: 0,
  tcLink: '',
  distributorIds: null,
  isApplicableForDirect: false,
  isApplicableForDistributor: false,
  accreditedInvestorApplicable: '',
  accreditedMinCommitmentAmount: null,
  managementFeeApplicable: '',
  managementFeeType: '',
  maxManagementFee: null,
  minManagementFee: null,
  hurdleRateApplicable: '',
  hurdleRateType: '',
  defaultHurdleRate: null,
  minHurdleRate: null,
  maxHurdleRate: null,
  performanceFeeApplicable: '',
  performanceFeeType: '',
  defaultPerformanceFee: null,
  minPerformanceFee: null,
  maxPerformanceFee: null,
  exitChargeApplicable: '',
  exitChargeType: '',
  defaultExitCharge: null,
  minExitCharge: null,
  maxExitCharge: null,
  setupFeeApplicable: '',
  setupFeeType: '',
  maxSetupFee: null,
  minSetupFee: null,
  setupFee: null,
  placementFeeApplicable: '',
  placementFeeType: '',
  maxPlacementFee: null,
  minPlacementFee: null,
  defaultPlacementFee: null,
  operatingExpenseApplicable: '',
  operatingExpenseType: '',
  maxOperatingExpense: null,
  minOperatingExpense: null,
  defaultOperatingExpenses: null,
  ...topUpPlanInitialValues,
};

export const Plan = ({
  values,
  access,
  fundManager,
  trustee,
  distributorList,
  setValues,
}: {
  values: NewPlanType;
  access: boolean;
  fundManager: FundManager[];
  trustee: Trustee[];
  distributorList: DistributorListType[];
  setValues: (values: NewPlanType) => void;
}): JSX.Element => {
  return (
    <Grid container rowSpacing={1} columnSpacing={4} px={2}>
      <SubHeading
        sx={{
          color: 'common.blue',
          letterSpacing: 0.5 + 'px',
          padding: { xs: '10px 10px', sm: '10px 30px' },
          ml: { xs: 3.5 },
        }}>
        {'Plan Details'}
      </SubHeading>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="planCode" label="Plan Code *" placeholder="Enter Plan Code" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="planDescription"
          label="Plan Description *"
          placeholder="Enter Plan Description"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="schemeNature"
          label="Scheme Nature *"
          placeholder="Enter Scheme Nature"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFSelectField
          name="schemeCategory"
          label="Scheme Category *"
          items={Object.keys(SCHEME_CATEGORY).map((cat) => ({
            key: SCHEME_CATEGORY[cat],
            value: cat,
          }))}
        />
      </Grid>
      {/* <Grid item xs={12} sm={6} md={4}>
                    <MFTextField
                      name="registrationNumber"
                      label="Registration Number *"
                      placeholder="Enter Registration Number"
                    />
                  </Grid> */}
      <Grid item xs={12} sm={6} md={4}>
        <MultipleSelect
          name="fundmanagerIds"
          label="Investment Manager(s) *"
          items={fundManager.map((item) => ({
            key: item.id,
            value: item.name,
          }))}
          renderText="Selected Investment Manager(s)"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFSelectField
          name="requiredFundManagers"
          label="No. of Investment Manager(s) to esign *"
          items={getArrayLength(values?.fundmanagerIds?.length || 1)?.map((thresholdValue) => ({
            key: thresholdValue,
            value: thresholdValue,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MultipleSelect
          name="trusteeIds"
          label="Trustee Name(s) *"
          items={trustee.map((item) => ({
            key: item.id,
            value: item.name,
          }))}
          renderText="Selected Trustee(s)"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFSelectField
          name="requiredTrustees"
          label="No. of Trustee(s) to esign *"
          items={getArrayLength(values?.trusteeIds?.length || 1)?.map((thresholdValue) => ({
            key: thresholdValue,
            value: thresholdValue,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="country" label="Country" placeholder="Enter Country" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFSelectField
          name="currency"
          label="Currency"
          items={Object.keys(CurrencyMaster).map((item) => ({
            key: CurrencyMaster[item].currency,
            value: item,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <DatePicker
          label={'Scheme Start Date'}
          inputLabelStyles={{
            transform: 'unset',

            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Scheme Start Date'}
          // maxDate={minDateForContributor()}
          name={`schemeStartDate`}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="lockInPeriod"
          label="Lock In Period(In Year)"
          placeholder="Enter Lock In Period(In Year)"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="lockInExtension"
          label="Lock In Extension(In Year)"
          disabled={!access}
          placeholder="Enter Lock In Extension(In Year)"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="faceValue"
          label="Face value"
          placeholder="Enter Face value"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="fundAddress1"
          label="Fund Address 1"
          placeholder="Enter Fund Address 1"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="fundAddress2"
          label="Fund Address 2"
          placeholder="Enter Fund Address 2"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="fundAddress3"
          label="Fund Address 3"
          placeholder="Enter Fund Address 3"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="custodian" label="Custodian" placeholder="Enter Custodian" />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UseRadioGroup
          formLabel="Setup Fee Applicable *"
          name={`setupFeeApplicable`}
          items={Object.keys(YES_NO_MASTER).map((item) => ({
            label: YES_NO_MASTER[item],
            value: item,
          }))}
        />
      </Grid>
      {isFieldApplicable(values.setupFeeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Setup Fee Type *"
              name={`setupFeeType`}
              items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                label: FEE_TYPE_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="setupFee"
              label="Setup Fee *"
              placeholder="Enter Setup Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {isFieldVariable(values.setupFeeType, values.setupFeeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="minSetupFee"
              label="Minimum Setup Fee *"
              placeholder="Enter Minimum Setup Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="maxSetupFee"
              label="Maximum Setup Fee *"
              placeholder="Enter Maximum Setup Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {/* <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="setupFee"
          label="Setup Fee"
          placeholder="Enter Setup Fee"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
      </Grid> */}
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="minCommitmentAmount"
          label="Minimum Commitment Amount *"
          placeholder="Enter Minimum Commitment Amount"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
        {values.minCommitmentAmount != 0 && (
          <Typography sx={{ fontSize: 13 }}>
            {currencyConversion(values.minCommitmentAmount, values.currency)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="maxCommitmentAmount"
          label="Maximum Commitment Amount *"
          placeholder="Enter Maximum Commitment Amount"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
        {values.maxCommitmentAmount != 0 && (
          <Typography sx={{ fontSize: 13 }}>
            {currencyConversion(values.maxCommitmentAmount, values.currency)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField
          name="minContributionPercentage"
          label="Minimum Contribution Percentage"
          placeholder="Enter Minimum Contribution Percentage"
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <MFTextField name="tcLink" label={`T&C Link`} placeholder={`Enter T&C Link`} />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <UseRadioGroup
          formLabel="Accredited Investor Applicable *"
          name={`accreditedInvestorApplicable`}
          items={Object.keys(YES_NO_MASTER).map((item) => ({
            label: YES_NO_MASTER[item],
            value: item,
          }))}
        />
      </Grid>
      {isAccreditedInvestorApplicable(values.accreditedInvestorApplicable) && (
        <Grid item xs={12} sm={6} md={4}>
          <MFTextField
            name="accreditedMinCommitmentAmount"
            label="Accredited Minimum Commitment Amount *"
            placeholder="Enter Accredited Minimum Commitment Amount"
            type="number"
            onKeyDown={(e) => {
              allowOnlyNumbers(e);
            }}
            trimOnBlur={false}
          />
          {values.accreditedMinCommitmentAmount != 0 && (
            <Typography sx={{ fontSize: 13 }}>
              {currencyConversion(values.accreditedMinCommitmentAmount, values?.currency)}
            </Typography>
          )}
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <UseRadioGroup
          formLabel="Management Fee Applicable *"
          name={`managementFeeApplicable`}
          items={Object.keys(YES_NO_MASTER).map((item) => ({
            label: YES_NO_MASTER[item],
            value: item,
          }))}
        />
      </Grid>
      {isFieldApplicable(values.managementFeeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Management Fee Type *"
              name={`managementFeeType`}
              items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                label: FEE_TYPE_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="defaultManagementFee"
              label="Management Fee *"
              placeholder="Enter Management Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {isFieldVariable(values.managementFeeType, values.managementFeeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="minManagementFee"
              label="Minimum Management Fee *"
              placeholder="Enter Minimum Management Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="maxManagementFee"
              label="Maximum Management Fee *"
              placeholder="Enter Maximum Management Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <UseRadioGroup
          formLabel="Hurdle Rate Applicable *"
          name={`hurdleRateApplicable`}
          items={Object.keys(YES_NO_MASTER).map((item) => ({
            label: YES_NO_MASTER[item],
            value: item,
          }))}
        />
      </Grid>
      {isFieldApplicable(values.hurdleRateApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Hurdle Rate Type *"
              name={`hurdleRateType`}
              items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                label: FEE_TYPE_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="defaultHurdleRate"
              label="Hurdle Rate *"
              placeholder="Enter Hurdle Rate"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {isFieldVariable(values.hurdleRateType, values.hurdleRateApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="minHurdleRate"
              label="Minimum Hurdle Rate *"
              placeholder="Enter Minimum Hurdle Rate"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="maxHurdleRate"
              label="Maximum Hurdle Rate *"
              placeholder="Enter Maximum Hurdle Rate"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <UseRadioGroup
          formLabel="Performance Fee Applicable *"
          name={`performanceFeeApplicable`}
          items={Object.keys(YES_NO_MASTER).map((item) => ({
            label: YES_NO_MASTER[item],
            value: item,
          }))}
        />
      </Grid>
      {isFieldApplicable(values.performanceFeeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Performance Fee Type *"
              name={`performanceFeeType`}
              items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                label: FEE_TYPE_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="defaultPerformanceFee"
              label="Performance Fee *"
              placeholder="Enter Performance Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {isFieldVariable(values.performanceFeeType, values.performanceFeeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="minPerformanceFee"
              label="Minimum Performance Fee *"
              placeholder="Enter Minimum Performance Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="maxPerformanceFee"
              label="Maximum Performance Fee *"
              placeholder="Enter Maximum Performance Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <UseRadioGroup
          formLabel="Exit Charge Applicable *"
          name={`exitChargeApplicable`}
          items={Object.keys(YES_NO_MASTER).map((item) => ({
            label: YES_NO_MASTER[item],
            value: item,
          }))}
        />
      </Grid>
      {isFieldApplicable(values.exitChargeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Exit Charge Type *"
              name={`exitChargeType`}
              items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                label: FEE_TYPE_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="defaultExitCharge"
              label="Exit Charge *"
              placeholder="Enter Exit Charge"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {isFieldVariable(values.exitChargeType, values.exitChargeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="minExitCharge"
              label="Minimum Exit Charge *"
              placeholder="Enter Minimum Exit Charge"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="maxExitCharge"
              label="Maximum Exit Charge *"
              placeholder="Enter Maximum Exit Charge"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <UseRadioGroup
          formLabel="Placement Fee Applicable *"
          name={`placementFeeApplicable`}
          items={Object.keys(YES_NO_MASTER).map((item) => ({
            label: YES_NO_MASTER[item],
            value: item,
          }))}
        />
      </Grid>
      {isFieldApplicable(values.placementFeeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Placement Fee Type *"
              name={`placementFeeType`}
              items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                label: FEE_TYPE_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="defaultPlacementFee"
              label="Placement Fee *"
              placeholder="Enter Placement Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {isFieldVariable(values.placementFeeType, values.placementFeeApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="minPlacementFee"
              label="Minimum Placement Fee *"
              placeholder="Enter Minimum Placement Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="maxPlacementFee"
              label="Maximum Placement Fee *"
              placeholder="Enter Placement Setup Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <UseRadioGroup
          formLabel="Operating Expenses Applicable *"
          name={`operatingExpenseApplicable`}
          items={Object.keys(YES_NO_MASTER).map((item) => ({
            label: YES_NO_MASTER[item],
            value: item,
          }))}
        />
      </Grid>
      {isFieldApplicable(values.operatingExpenseApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Operating Expenses Type *"
              name={`operatingExpenseType`}
              items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                label: FEE_TYPE_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="defaultOperatingExpenses"
              label="Operating Expenses *"
              placeholder="Enter Operating Expenses"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {isFieldVariable(values.operatingExpenseType, values.operatingExpenseApplicable) && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="minOperatingExpense"
              label="Minimum Operating Expenses *"
              placeholder="Enter Minimum Operating Expenses"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="maxOperatingExpense"
              label="Maximum Operating Fee *"
              placeholder="Enter Maximum Operating Fee"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
        </>
      )}
      {/* empty grid for proper alignment */}
      <Grid xs={12}></Grid>
      <Grid item xs={12} sm={6} sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <MFCheckbox name={`isApplicableForDirect`} label="Direct" />
          <MFCheckbox
            name={`isApplicableForDistributor`}
            label="Distributor"
            onChange={({ target: { checked } }) => {
              setValues({
                ...values,
                isApplicableForDistributor: checked,
                distributorIds: [],
              });
            }}
            checked={values.isApplicableForDistributor}
          />
        </Box>
        {values.isApplicableForDistributor && (
          <MultipleSelect
            name="distributorIds"
            label="Applicable Distributor(s) *"
            items={distributorList.map((item) => ({
              key: item.id,
              value: `${item.arnCode || 'N/A'} - ${item.name} (${item.type})`,
            }))}
            renderText="Selected Distributor(s)"
          />
        )}
      </Grid>{' '}
    </Grid>
  );
};

export const TopUpPlan = ({ values }: { values: NewPlanType }): JSX.Element => {
  return (
    <Grid container rowSpacing={1} columnSpacing={4} px={2}>
      <SubHeading>Top Up Section</SubHeading>
      <Grid item xs={12} sm={6} md={4}>
        <MFCheckbox name={`isTopupApplicable`} label="Top Up Applicable" />
      </Grid>
      {values.isTopupApplicable && (
        <>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topUpMinCommitmentAmount"
              label="Minimum Top Up Commitment Amount *"
              placeholder="Enter Minimum Top Up Commitment Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
            {values.topUpMinCommitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topUpMinCommitmentAmount, values.currency)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topUpMaxCommitmentAmount"
              label="Maximum Top Up Commitment Amount *"
              placeholder="Enter Maximum Top Up Commitment Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
            {values.topUpMaxCommitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.topUpMaxCommitmentAmount, values.currency)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MFTextField
              name="topUpMinContributionPercentage"
              label="Minimum Top Up Contribution Percentage"
              placeholder="Enter Minimum Top Up Contribution Percentage"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Top Up Setup Fee Applicable *"
              name={`topUpSetupFeeApplicable`}
              items={Object.keys(YES_NO_MASTER).map((item) => ({
                label: YES_NO_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          {isFieldApplicable(values.topUpSetupFeeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <UseRadioGroup
                  formLabel="Top Up Setup Fee Type *"
                  name={`topUpSetupFeeType`}
                  items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                    label: FEE_TYPE_MASTER[item],
                    value: item,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpSetupFee"
                  label="Top Up Setup Fee *"
                  placeholder="Enter Setup Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          {isFieldVariable(values.topUpSetupFeeType, values.topUpSetupFeeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMinSetupFee"
                  label="Top Up Minimum Setup Fee *"
                  placeholder="Enter Minimum Setup Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMaxSetupFee"
                  label="Top Up Maximum Setup Fee *"
                  placeholder="Enter Maximum Setup Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Top Up Accredited Investor Applicable *"
              name={`topUpAccreditedInvestorApplicable`}
              items={Object.keys(YES_NO_MASTER).map((item) => ({
                label: YES_NO_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          {isAccreditedInvestorApplicable(values.topUpAccreditedInvestorApplicable) && (
            <Grid item xs={12} sm={6} md={4}>
              <MFTextField
                name="topUpAccreditedMinCommitmentAmount"
                label="Top Up Accredited Minimum Commitment Amount *"
                placeholder="Enter Top Up Accredited Minimum Commitment Amount"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
              />
              {values.topUpAccreditedMinCommitmentAmount != 0 && (
                <Typography sx={{ fontSize: 13 }}>
                  {currencyConversion(values.topUpAccreditedMinCommitmentAmount, values?.currency)}
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Top Up Management Fee Applicable *"
              name={`topUpManagementFeeApplicable`}
              items={Object.keys(YES_NO_MASTER).map((item) => ({
                label: YES_NO_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          {isFieldApplicable(values.topUpManagementFeeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <UseRadioGroup
                  formLabel="Top Up Management Fee Type *"
                  name={`topUpManagementFeeType`}
                  items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                    label: FEE_TYPE_MASTER[item],
                    value: item,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="defaultTopUpManagementFee"
                  label="Top Up Management Fee *"
                  placeholder="Enter Top Up Management Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          {isFieldVariable(values.topUpManagementFeeType, values.topUpManagementFeeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMinManagementFee"
                  label="Minimum Top Up Management Fee *"
                  placeholder="Enter Minimum Top Up Management Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMaxManagementFee"
                  label="Maximum Top Up Management Fee *"
                  placeholder="Enter Maximum Top Up Management Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Top Up Hurdle Rate Applicable *"
              name={`topUpHurdleRateApplicable`}
              items={Object.keys(YES_NO_MASTER).map((item) => ({
                label: YES_NO_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          {isFieldApplicable(values.topUpHurdleRateApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <UseRadioGroup
                  formLabel="Top Up Hurdle Rate Type *"
                  name={`topUpHurdleRateType`}
                  items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                    label: FEE_TYPE_MASTER[item],
                    value: item,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="defaultTopUpHurdleRate"
                  label="Top Up Hurdle Rate *"
                  placeholder="Enter Top Up Hurdle Rate"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          {isFieldVariable(values.topUpHurdleRateType, values.topUpHurdleRateApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMinHurdleRate"
                  label="Minimum Top Up Hurdle Rate *"
                  placeholder="Enter Minimum Top Up Hurdle Rate"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMaxHurdleRate"
                  label="Maximum Top Up Hurdle Rate *"
                  placeholder="Enter Maximum Top Up Hurdle Rate"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Top Up Performance Fee Applicable *"
              name={`topUpPerformanceFeeApplicable`}
              items={Object.keys(YES_NO_MASTER).map((item) => ({
                label: YES_NO_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          {isFieldApplicable(values.topUpPerformanceFeeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <UseRadioGroup
                  formLabel="Top Up Performance Fee Type *"
                  name={`topUpPerformanceFeeType`}
                  items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                    label: FEE_TYPE_MASTER[item],
                    value: item,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="defaultTopUpPerformanceFee"
                  label="Top Up Performance Fee *"
                  placeholder="Enter Top Up Performance Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          {isFieldVariable(
            values.topUpPerformanceFeeType,
            values.topUpPerformanceFeeApplicable
          ) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMinPerformanceFee"
                  label="Minimum Top Up  Performance Fee *"
                  placeholder="Enter Minimum Top Up Performance Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMaxPerformanceFee"
                  label="Maximum Top Up Performance Fee *"
                  placeholder="Enter Maximum Top Up Performance Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Top Up Exit Charge Applicable *"
              name={`topUpExitChargeApplicable`}
              items={Object.keys(YES_NO_MASTER).map((item) => ({
                label: YES_NO_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          {isFieldApplicable(values.topUpExitChargeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <UseRadioGroup
                  formLabel="Top Up Exit Charge Type *"
                  name={`topUpExitChargeType`}
                  items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                    label: FEE_TYPE_MASTER[item],
                    value: item,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="defaultTopUpExitCharge"
                  label="Top Up Exit Charge *"
                  placeholder="Enter Top Up Exit Charge"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          {isFieldVariable(values.topUpExitChargeType, values.topUpExitChargeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMinExitCharge"
                  label="Minimum Top Up Exit Charge *"
                  placeholder="Enter Minimum Top Up Exit Charge"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMaxExitCharge"
                  label="Maximum Top Up Exit Charge *"
                  placeholder="Enter Maximum Top Up Exit Charge"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Top Up Placement Fee Applicable *"
              name={`topUpPlacementFeeApplicable`}
              items={Object.keys(YES_NO_MASTER).map((item) => ({
                label: YES_NO_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          {isFieldApplicable(values.topUpPlacementFeeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <UseRadioGroup
                  formLabel="Top Up Placement Fee Type *"
                  name={`topUpPlacementFeeType`}
                  items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                    label: FEE_TYPE_MASTER[item],
                    value: item,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="defaultTopUpPlacementFee"
                  label="Top Up Placement Fee *"
                  placeholder="Enter Top Up Placement Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          {isFieldVariable(values.topUpPlacementFeeType, values.topUpPlacementFeeApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMinPlacementFee"
                  label="Minimum Top Up Placement Fee *"
                  placeholder="Enter Minimum Top Up Placement Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMaxPlacementFee"
                  label="Maximum Top Up Placement Fee *"
                  placeholder="Enter Maximum Top Up Placement Setup Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <UseRadioGroup
              formLabel="Top Up Operating Expenses Applicable *"
              name={`topUpOperatingExpenseApplicable`}
              items={Object.keys(YES_NO_MASTER).map((item) => ({
                label: YES_NO_MASTER[item],
                value: item,
              }))}
            />
          </Grid>
          {isFieldApplicable(values.topUpOperatingExpenseApplicable) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <UseRadioGroup
                  formLabel="Top Up Operating Expenses Type *"
                  name={`topUpOperatingExpenseType`}
                  items={Object.keys(FEE_TYPE_MASTER).map((item) => ({
                    label: FEE_TYPE_MASTER[item],
                    value: item,
                  }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="defaultTopUpOperatingExpenses"
                  label="Top Up Operating Expenses *"
                  placeholder="Enter Top Up Operating Expenses"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
          {isFieldVariable(
            values.topUpOperatingExpenseType,
            values.topUpOperatingExpenseApplicable
          ) && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMinOperatingExpense"
                  label="Minimum Top Up Operating Expenses *"
                  placeholder="Enter Minimum Top Up Operating Expenses"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MFTextField
                  name="topUpMaxOperatingExpense"
                  label="Maximum Top Up Operating Fee *"
                  placeholder="Enter Maximum Top Up Operating Fee"
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default function AddPlan({
  location,
}: {
  location: Location<{ fundId: string; PlanId: string }>;
}): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();

  const [fundManager, setFundManager] = useState<FundManager[]>([]);
  const [loading, setLoading] = useState(false);
  const [planInitial, setPlanInitial] = useState(initialValues);
  const [access, setAccess] = useState(false);
  const { fundId, PlanId } = location.state || {};
  const [trustee, setTrustee] = useState<Trustee[]>([]);
  const [distributorList, setDistributorList] = useState<DistributorListType[]>([]);

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        setLoading(true);
        const trusteeList = (await dispatch(getTrustessList())) as unknown as Trustee[];
        const fundManagerList = (await dispatch(getFundManagersList())) as unknown as FundManager[];
        const { fundSetupAccess } = (await dispatch(fundAccess())) as unknown as accessType;
        const distributorLists = (await dispatch(
          getDistributorsListForFilters({ isActive: true })
        )) as unknown as DistributorListType[];
        setAccess(fundSetupAccess);
        setFundManager(fundManagerList?.filter((fundManager) => fundManager?.name));
        setTrustee(trusteeList.filter((trustee) => trustee?.name));
        setDistributorList(distributorLists);
        if (PlanId) {
          const GetFundDetail = (await dispatch(
            getPlanDetail(PlanId)
          )) as unknown as ClassPlanProps;
          const {
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage,
            defaultManagementFee,
            fundmanagerDetails,
            trusteeDetails,
            requiredTrustees,
            requiredFundManagers,
            tcLink,
            distributorDetails,
            isApplicableForDirect,
            accreditedInvestorApplicable,
            accreditedMinCommitmentAmount,
            managementFeeApplicable,
            managementFeeType,
            maxManagementFee,
            minManagementFee,
            hurdleRateApplicable,
            hurdleRateType,
            defaultHurdleRate,
            minHurdleRate,
            maxHurdleRate,
            performanceFeeApplicable,
            performanceFeeType,
            defaultPerformanceFee,
            minPerformanceFee,
            maxPerformanceFee,
            exitChargeApplicable,
            exitChargeType,
            defaultExitCharge,
            minExitCharge,
            maxExitCharge,
            setupFeeApplicable,
            setupFeeType,
            maxSetupFee,
            minSetupFee,
            placementFeeApplicable,
            placementFeeType,
            maxPlacementFee,
            minPlacementFee,
            defaultPlacementFee,
            operatingExpenseApplicable,
            operatingExpenseType,
            maxOperatingExpense,
            minOperatingExpense,
            defaultOperatingExpenses,
          } = GetFundDetail || {};

          const topupValues = {
            isTopupApplicable: GetFundDetail?.isTopupApplicable || false,
            topUpSetupFeeApplicable: GetFundDetail?.topUpSetupFeeApplicable,
            topUpSetupFeeType: GetFundDetail?.topUpSetupFeeType,
            topUpMaxSetupFee: GetFundDetail?.topUpMaxSetupFee,
            topUpMinSetupFee: GetFundDetail?.topUpMinSetupFee,
            topUpSetupFee: GetFundDetail?.topUpSetupFee,
            topUpMaxCommitmentAmount: GetFundDetail?.topUpMaxCommitmentAmount,
            topUpMinCommitmentAmount: GetFundDetail?.topUpMinCommitmentAmount,
            topUpMinContributionPercentage: GetFundDetail?.topUpMinContributionPercentage,
            topUpAccreditedInvestorApplicable: GetFundDetail?.topUpAccreditedInvestorApplicable,
            topUpAccreditedMinCommitmentAmount: GetFundDetail?.topUpAccreditedMinCommitmentAmount,
            topUpManagementFeeApplicable: GetFundDetail?.topUpManagementFeeApplicable,
            topUpManagementFeeType: GetFundDetail?.topUpManagementFeeType,
            topUpMaxManagementFee: GetFundDetail?.topUpMaxManagementFee,
            topUpMinManagementFee: GetFundDetail?.topUpMinManagementFee,
            defaultTopUpManagementFee: GetFundDetail?.defaultTopUpManagementFee,
            topUpHurdleRateApplicable: GetFundDetail?.topUpHurdleRateApplicable,
            topUpHurdleRateType: GetFundDetail?.topUpHurdleRateType,
            topUpMaxHurdleRate: GetFundDetail?.topUpMaxHurdleRate,
            topUpMinHurdleRate: GetFundDetail?.topUpMinHurdleRate,
            defaultTopUpHurdleRate: GetFundDetail?.defaultTopUpHurdleRate,
            topUpPerformanceFeeApplicable: GetFundDetail?.topUpPerformanceFeeApplicable,
            topUpPerformanceFeeType: GetFundDetail?.topUpPerformanceFeeType,
            topUpMaxPerformanceFee: GetFundDetail?.topUpMinPerformanceFee,
            topUpMinPerformanceFee: GetFundDetail?.topUpMaxPerformanceFee,
            defaultTopUpPerformanceFee: GetFundDetail?.defaultTopUpPerformanceFee,
            topUpExitChargeApplicable: GetFundDetail?.topUpExitChargeApplicable,
            topUpExitChargeType: GetFundDetail?.topUpExitChargeType,
            topUpMaxExitCharge: GetFundDetail?.topUpMaxExitCharge,
            topUpMinExitCharge: GetFundDetail?.topUpMinExitCharge,
            defaultTopUpExitCharge: GetFundDetail?.defaultTopUpExitCharge,
            topUpPlacementFeeApplicable: GetFundDetail?.topUpPlacementFeeApplicable,
            topUpPlacementFeeType: GetFundDetail?.topUpPlacementFeeType,
            topUpMaxPlacementFee: GetFundDetail?.topUpMaxPlacementFee,
            topUpMinPlacementFee: GetFundDetail?.topUpMinPlacementFee,
            defaultTopUpPlacementFee: GetFundDetail?.defaultTopUpPlacementFee,
            topUpOperatingExpenseApplicable: GetFundDetail?.topUpOperatingExpenseApplicable,
            topUpOperatingExpenseType: GetFundDetail?.topUpOperatingExpenseType,
            topUpMaxOperatingExpense: GetFundDetail?.topUpMaxOperatingExpense,
            topUpMinOperatingExpense: GetFundDetail?.topUpMinOperatingExpense,
            defaultTopUpOperatingExpenses: GetFundDetail?.defaultTopUpOperatingExpenses,
          };

          setPlanInitial({
            ...planInitial,
            planCode,
            schemeId,
            planDescription,
            schemeNature,
            registrationNumber,
            schemeCategory,
            country,
            currency: currency?.toLowerCase() || defaultCurrency,
            schemeStartDate,
            lockInPeriod,
            lockInExtension,
            inActive,
            faceValue,
            fundAddress1,
            fundAddress2,
            fundAddress3,
            isActive,
            custodian,
            setupFee,
            minCommitmentAmount,
            maxCommitmentAmount,
            minContributionPercentage,
            defaultManagementFee,
            fundmanagerIds: (
              fundmanagerDetails?.fundmanagerIds ||
              planInitial.fundmanagerIds ||
              []
            )?.map((item) => Number(item)),
            trusteeIds: (trusteeDetails?.trusteeIds || planInitial.trusteeIds || [])?.map((item) =>
              Number(item)
            ),
            requiredTrustees: requiredTrustees || planInitial.requiredTrustees,
            requiredFundManagers: requiredFundManagers || planInitial.requiredFundManagers,
            tcLink,
            distributorIds: distributorDetails.distributorIds || null,
            isApplicableForDirect: isApplicableForDirect || false,
            isApplicableForDistributor: distributorDetails.distributorIds.length > 0 ? true : false,
            accreditedInvestorApplicable,
            accreditedMinCommitmentAmount,
            managementFeeApplicable,
            managementFeeType,
            maxManagementFee,
            minManagementFee,
            hurdleRateApplicable,
            hurdleRateType,
            defaultHurdleRate,
            minHurdleRate,
            maxHurdleRate,
            performanceFeeApplicable,
            performanceFeeType,
            defaultPerformanceFee,
            minPerformanceFee,
            maxPerformanceFee,
            exitChargeApplicable,
            exitChargeType,
            defaultExitCharge,
            minExitCharge,
            maxExitCharge,
            setupFeeApplicable,
            setupFeeType,
            maxSetupFee,
            minSetupFee,
            placementFeeApplicable,
            placementFeeType,
            maxPlacementFee,
            minPlacementFee,
            defaultPlacementFee,
            operatingExpenseApplicable,
            operatingExpenseType,
            maxOperatingExpense,
            minOperatingExpense,
            defaultOperatingExpenses,
            ...topupValues,
          });
        }
        if (!isComponentAlive) return;
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentAlive) {
          setLoading(false);
        }
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, []);

  const onSubmit = async (values: NewPlanType) => {
    try {
      const {
        planCode,
        schemeId,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        setupFee,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage,
        defaultManagementFee,
        fundmanagerIds,
        trusteeIds,
        requiredTrustees,
        requiredFundManagers,
        tcLink,
        distributorIds,
        isApplicableForDirect,
        accreditedInvestorApplicable,
        accreditedMinCommitmentAmount,
        managementFeeApplicable,
        managementFeeType,
        maxManagementFee,
        minManagementFee,
        hurdleRateApplicable,
        hurdleRateType,
        defaultHurdleRate,
        minHurdleRate,
        maxHurdleRate,
        performanceFeeApplicable,
        performanceFeeType,
        defaultPerformanceFee,
        minPerformanceFee,
        maxPerformanceFee,
        exitChargeApplicable,
        exitChargeType,
        defaultExitCharge,
        minExitCharge,
        maxExitCharge,
        setupFeeApplicable,
        setupFeeType,
        maxSetupFee,
        minSetupFee,
        placementFeeApplicable,
        placementFeeType,
        maxPlacementFee,
        minPlacementFee,
        defaultPlacementFee,
        operatingExpenseApplicable,
        operatingExpenseType,
        maxOperatingExpense,
        minOperatingExpense,
        defaultOperatingExpenses,
      } = values;
      const updatedTopUpValues = UpdatedTopUpValues(values);
      const updatedFundmanagerIds =
        fundmanagerIds?.sort((id1, id2) => Number(id1) - Number(id2))?.map((f) => Number(f)) || [];
      const updatedTrusteeIds =
        trusteeIds?.sort((id1, id2) => Number(id1) - Number(id2))?.map((f) => Number(f)) || [];
      const updatedValues = {
        schemeId: !PlanId ? fundId : schemeId,
        planCode,
        planDescription,
        schemeNature,
        registrationNumber,
        schemeCategory,
        country,
        currency,
        schemeStartDate,
        lockInPeriod,
        lockInExtension,
        fundmanagerIds: updatedFundmanagerIds,
        trusteeIds: updatedTrusteeIds,
        fundmanagerDetails: {
          fundmanagerIds: updatedFundmanagerIds,
          fundmanagers: fundManager
            ?.sort((fund1, fund2) => Number(fund1?.id) - Number(fund2?.id))
            ?.filter((f) => updatedFundmanagerIds.includes(Number(f.id))),
        },
        trusteeDetails: {
          trusteeIds: updatedTrusteeIds,
          trustees: trustee
            ?.sort((trustee1, trustee2) => Number(trustee1?.id) - Number(trustee2?.id))
            ?.filter((f) => updatedTrusteeIds.includes(Number(f.id))),
        },
        requiredFundManagers: Number(requiredFundManagers),
        requiredTrustees: Number(requiredTrustees),
        inActive,
        faceValue,
        fundAddress1,
        fundAddress2,
        fundAddress3,
        isActive,
        custodian,
        // setupFee: setupFee ? setupFee : 0,
        minCommitmentAmount,
        maxCommitmentAmount,
        minContributionPercentage: minContributionPercentage ? minContributionPercentage : 0,
        tcLink,
        distributorIds,
        isApplicableForDirect,
        accreditedInvestorApplicable,
        accreditedMinCommitmentAmount: isAccreditedInvestorApplicable(accreditedInvestorApplicable)
          ? accreditedMinCommitmentAmount
          : null,
        managementFeeApplicable,
        managementFeeType: isFieldApplicable(managementFeeApplicable) ? managementFeeType : null,
        defaultManagementFee: isFieldApplicable(managementFeeApplicable) ? defaultManagementFee : 0,
        maxManagementFee: isFieldVariable(managementFeeType, managementFeeApplicable)
          ? maxManagementFee
          : null,
        minManagementFee: isFieldVariable(managementFeeType, managementFeeApplicable)
          ? minManagementFee
          : null,
        hurdleRateApplicable,
        defaultHurdleRate: isFieldApplicable(hurdleRateApplicable) ? defaultHurdleRate : null,
        hurdleRateType: isFieldApplicable(hurdleRateApplicable) ? hurdleRateType : null,
        maxHurdleRate: isFieldVariable(hurdleRateType, hurdleRateApplicable) ? maxHurdleRate : null,
        minHurdleRate: isFieldVariable(hurdleRateType, hurdleRateApplicable) ? minHurdleRate : null,
        performanceFeeApplicable,
        defaultPerformanceFee: isFieldApplicable(performanceFeeApplicable)
          ? defaultPerformanceFee
          : null,
        performanceFeeType: isFieldApplicable(performanceFeeApplicable) ? performanceFeeType : null,
        maxPerformanceFee: isFieldVariable(performanceFeeType, performanceFeeApplicable)
          ? maxPerformanceFee
          : null,
        minPerformanceFee: isFieldVariable(performanceFeeType, performanceFeeApplicable)
          ? minPerformanceFee
          : null,
        exitChargeApplicable,
        defaultExitCharge: isFieldApplicable(exitChargeApplicable) ? defaultExitCharge : null,
        exitChargeType: isFieldApplicable(exitChargeApplicable) ? exitChargeType : null,
        maxExitCharge: isFieldVariable(exitChargeType, exitChargeApplicable) ? maxExitCharge : null,
        minExitCharge: isFieldVariable(exitChargeType, exitChargeApplicable) ? minExitCharge : null,
        managementFee: 0,
        setupFeeApplicable,
        setupFeeType: isFieldApplicable(setupFeeApplicable) ? setupFeeType : null,
        setupFee: isFieldApplicable(setupFeeApplicable) ? setupFee : 0,
        maxSetupFee: isFieldVariable(setupFeeType, setupFeeApplicable) ? maxSetupFee : null,
        minSetupFee: isFieldVariable(setupFeeType, setupFeeApplicable) ? minSetupFee : null,
        placementFeeApplicable,
        placementFeeType: isFieldApplicable(placementFeeApplicable) ? placementFeeType : null,
        defaultPlacementFee: isFieldApplicable(placementFeeApplicable) ? defaultPlacementFee : 0,
        maxPlacementFee: isFieldVariable(placementFeeType, placementFeeApplicable)
          ? maxPlacementFee
          : null,
        minPlacementFee: isFieldVariable(placementFeeType, placementFeeApplicable)
          ? minPlacementFee
          : null,
        operatingExpenseApplicable,
        operatingExpenseType: isFieldApplicable(operatingExpenseApplicable)
          ? operatingExpenseType
          : null,
        maxOperatingExpense: isFieldVariable(operatingExpenseType, operatingExpenseApplicable)
          ? maxOperatingExpense
          : null,
        minOperatingExpense: isFieldVariable(operatingExpenseType, operatingExpenseApplicable)
          ? minOperatingExpense
          : null,
        defaultOperatingExpenses: isFieldApplicable(operatingExpenseApplicable)
          ? defaultOperatingExpenses
          : 0,
        ...updatedTopUpValues,
      };
      if (!PlanId) {
        await dispatch(addPlan(updatedValues));
      } else {
        await dispatch(updatePlan(Number(PlanId), updatedValues));
      }
      history.push(PlanId ? 'plan-details' : 'view-plans', {
        fundId: fundId,
        PlanId: PlanId,
      });
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <>
      <Formik
        initialValues={planInitial}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={addPlanValidation}>
        {({ handleSubmit, values, setValues }) => (
          <Box sx={styles.UsersFormikBox} component="form" noValidate onSubmit={handleSubmit}>
            <Hidden smUp={true}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: '#1C2D47',
                  }}>
                  Back
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  my: 2,
                  py: 2,
                  pl: { xs: 3, sm: 0 },
                  color: 'primary.main',
                  bgcolor: 'rgba(238, 244, 251, 0.5)',
                }}>
                <PersonOutlineOutlinedIcon fontSize="large" />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    pl: 1,
                  }}>
                  Fund Setup
                </Typography>
              </Box>
            </Hidden>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Hidden only="xs">
                <IconButton
                  sx={{ height: 'fit-content', p: 0 }}
                  onClick={() =>
                    history.push(PlanId ? 'plan-details' : 'view-plans', {
                      fundId: fundId,
                      // planId: PlanId,
                      PlanId: PlanId,
                    })
                  }>
                  <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
                </IconButton>
              </Hidden>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  color: '#1C2D47',
                  pl: { xs: 3, sm: 0 },
                }}>
                {PlanId ? 'Edit Plan' : 'Add Plan'}
              </Typography>
            </Box>

            {loading ? (
              <LinearProgress sx={{ mt: 2 }} />
            ) : (
              <>
                <Plan
                  values={values}
                  access={access}
                  fundManager={fundManager}
                  trustee={trustee}
                  distributorList={distributorList}
                  setValues={(e) => setValues(e as NewPlanType)}
                />
                <TopUpPlan values={values} />
                {access && (
                  <Box sx={{ width: '100%', maxWidth: '350px', mx: 'auto', mt: 3 }}>
                    <MFSubmitButton label="Save" />
                  </Box>
                )}
              </>
            )}
          </Box>
        )}
      </Formik>
    </>
  );
}
